import React from 'react';
import { HeadFC } from 'gatsby';

import ExactaLovers from '../components/pages/ExactaLovers';
import Seo from '../components/Seo';

const ExactaLoversPage = () => {
  return <ExactaLovers />;
};

export const Head: HeadFC = () => (
  <Seo title="Exactalovers" canonical="/exacta-lovers/" />
);

export default ExactaLoversPage;
