import styled, { css } from 'styled-components';
import { device } from '../../../styles/media';

import gradient from '../../../../static/exactalovers/gradient.svg';
import arrow from '../../../../static/exactalovers/arrow.svg';

export const Link = styled.a`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.medium};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    background-color: ${theme.colors.green};
    border-radius: 0.8rem;
    padding: 1.2rem 2rem;
    width: fit-content;
    cursor: pointer;

    :hover {
      background-color: ${theme.colors.pink};
    }

    @media ${device.tablet} {
      height: 4.8rem;
      font-size: ${theme.font.sizes.medium};
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6.4rem;

    ${Main}, ${Section} {
      p {
        max-width: 62rem;
        font-size: ${theme.font.sizes.medium};
        line-height: 1.5;
      }

      img {
        object-fit: cover;
      }
    }

    a {
      color: ${theme.colors.black};
    }

    @media ${device.tablet} {
      padding-top: 4rem;
      gap: 8rem;

      ${Main}, ${Section} {
        padding: 0 3.2rem;
      }

      ${Main} {
        position: relative;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        justify-items: left;
        gap: 5.8rem 8rem;

        ::after {
          top: 50%;
          left: 50%;
          content: '';
          position: absolute;
          transform: translate(-50%, -30%);
          background: url(${arrow}) no-repeat;
          background-position: center;
          background-size: contain;
          height: 16rem;
          width: 18rem;
        }

        h1 {
          font-size: ${theme.heading.sizes.h5};
        }

        & > img {
          width: 100%;
          height: 100%;
          max-height: 64rem;
          max-width: 56rem;
        }
      }

      ${Title} {
        font-size: ${theme.heading.sizes.h5};
      }

      ${Heading} {
        padding: 0;
        transform: none;
        grid-column: 1;
        grid-row: 1;

        ${Link} {
          margin: 2.4rem 0 0;
        }
      }

      ${List} {
        width: 100%;
        max-width: unset;
        grid-column: span 2;
        flex-direction: row;
        margin-top: 0;
      }

      ${ListItem} {
        flex: 1;
        display: grid;
        align-items: center;
        justify-items: center;
        padding: 1.6rem 0.8rem;
        text-align: center;
        max-width: 16rem;
      }

      ${Inovation} {
        display: grid;
        grid-template-columns: 4fr 6fr;
        background: url(${gradient}) no-repeat;
        background-size: cover;
        padding: 2.4rem 6rem;
        gap: 1.6rem;

        h2 {
          margin: 0 0 0.8rem;
        }

        ${Title} {
          grid-row: 1;
          grid-column: span 2;
          max-width: unset;
        }

        .lovers-img-wrapper,
        img {
          grid-row: span 3;
          height: 100%;
          width: 30rem;
        }

        p {
          grid-column: 2;

          :last-of-type {
            grid-column: span 2;
          }
        }

        ${Link} {
          grid-column: 2;
          margin: 1.6rem 0 0 auto;
        }
      }

      ${Commitment} img {
        width: 100%;
      }
    }

    @media ${device.laptop} {
      ${Section} {
        padding: 0 8%;
      }

      ${ListItem} {
        height: 14rem;
        width: 14rem;
        font-size: ${theme.font.sizes.medium};
      }

      ${Main} {
        padding: 0 4% 0 8%;

        h1 {
          font-size: ${theme.heading.sizes.h3};
        }

        p {
          font-size: ${theme.font.sizes.xmedium};
        }
      }

      ${Inovation} {
        padding-top: 9.6rem;
        padding-bottom: 14rem;
        grid-template-columns: 5fr 5fr;
        gap: 2.4rem 6.4rem;

        ${Title} {
          text-align: left;
        }

        ${Title},
        p, ${Link} {
          grid-column: 2 !important;
        }

        .lovers-img-wrapper,
        img {
          width: 100%;
          grid-row: span 6;
          height: 100%;
        }

        ${Link} {
          margin: 1.6rem 0 auto;
        }
      }
    }

    @media ${device.laptopL} {
      padding-top: 5em;
      gap: 10rem;

      ${Main}, ${Section} {
        p {
          font-size: ${theme.font.sizes.xmedium};
        }
      }

      ${ListItem} {
        width: 20rem;
        height: 20rem;
        font-size: ${theme.font.sizes.xmedium};
        max-width: 20rem;

        img {
          height: 5.2rem;
          width: 5.2rem;
        }
      }

      ${Main} {
        h1 {
          font-size: ${theme.heading.sizes.h2};
        }

        p {
          font-size: ${theme.font.sizes.xxmedium};
        }

        ::after {
          width: 10vw;
        }
      }

      ${Heading} {
        margin-left: 6.4rem;
      }

      ${Inovation} {
        grid-template-columns: max-content;

        .lovers-img-wrapper,
        img {
          max-width: min(40vw, 90rem);
        }
      }
    }
  `}
`;

export const Main = styled.main`
  display: grid;
  justify-items: center;

  p {
    width: 100%;
  }

  & > img {
    width: 100%;
    object-position: top;
    max-height: 56rem;
    min-height: 36rem;
    height: 46vh;
  }
`;

export const Heading = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    transform: translateY(-4rem);
    padding: 0 2.4rem;
    gap: 2.4rem;

    h1 {
      font-weight: inherit;
      font-weight: ${theme.font.light};
      font-size: ${theme.heading.sizes.h5};
    }

    strong {
      font-style: italic;
      color: ${theme.colors.green};
    }

    ${Link} {
      margin: 0 auto;
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    font-size: ${theme.font.sizes.medium};
    margin-top: 2.4rem;
    list-style: none;
    max-width: 24rem;
    gap: 0.8rem;
  `}
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-weight: ${theme.font.bold};
    background: ${theme.colors.darkblue};
    font-size: ${theme.font.sizes.small};
    padding: 1.6rem 2.4rem;
    font-style: italic;
    gap: 1.6rem;

    img {
      width: 4rem;
      min-width: 4rem;
      height: 4rem;
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    line-height: 1.5;
    font-size: ${theme.font.sizes.xxmedium};
    color: ${theme.colors.green};
    text-align: center;
    font-style: italic;
    margin: 0 auto;
  `}
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.4rem;
  gap: 2.4rem;

  p {
    width: 100%;
  }

  ${Link} {
    margin-top: 3.2rem;
  }
`;

export const Inovation = styled(Section)`
  ${Title} {
    margin-top: 1.6rem;
    max-width: 36rem;
  }

  .lovers-img-wrapper,
  img {
    height: min(50vh, 40rem);
    width: calc(100% - 4.8rem);
    margin: 0 auto;
  }
`;

export const Commitment = styled(Section)`
  text-align: center;
  gap: 2.4rem;

  ${Title} {
    margin-top: 2.4rem;
  }

  img {
    width: 100%;
    height: 100%;
    min-height: 26rem;
    max-height: 56rem;
  }
`;
